'use client';

import { Divider } from '@nextui-org/react';

export default function Footer() {
  return (
    <div className="w-full h-10 font-sans text-[#A1A1AA] text-sm flex items-center justify-center">
      <span className="mr-20">Copyright © 2024-2025 南京相嵌科技有限公司</span>
      <span>苏ICP备2024092953号</span>
      <Divider
        orientation="vertical"
        className="mx-2 h-4 text-[#A1A1AA] w-0.5"
      />
      <span>苏公网安备32010602011503号</span>
    </div>
  );
}
