/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-02-23 10:47:08
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-03-05 19:55:50
 * @FilePath: /xiangqian-web/app/components/homeTextArea.js
 * @Description:
 */
'use client';
import { Input } from '@nextui-org/input';
import { Button, Listbox, ListboxItem } from '@nextui-org/react';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next-nprogress-bar';
import Image from 'next/image';
import { useMemo, useState } from 'react';
import { RECOMMEND_LIST } from '../config';
import ArrowRightIcon from '../icons/arrow-right.svg';
import FundIcon from '../icons/fund.svg';
import PaperEnIcon from '../icons/paper_en.svg';
import PaperZhIcon from '../icons/paper_zh.svg';
import SearchIcon from '../icons/search-icon.svg';
import mainState, { SearchMode } from '../models/model';

const HomeTextArea = observer(() => {
  const router = useRouter();
  const [searchValue, setSearchValue] = useState('');
  const [isInputFocus, setIsInputFocus] = useState(false);

  const listItems = useMemo(() => {
    return RECOMMEND_LIST.map((q) => ({
      label: q,
      key: q,
      endContent: (
        <Image src={ArrowRightIcon} width={24} height={24} alt="arrow-right" />
      ),
    }));
  }, []);

  return (
    <div
      className={`flex w-full max-w-[780px] bg-white rounded-[13px] mt-[50px] flex-col border-1 border-solid border-transparent transition-all duration-300 ${isInputFocus ? 'border-black' : ''}`}
    >
      <div className="flex w-full max-w-[780px]  rounded-[13px] py-[10px] px-[20px]">
        <Input
          classNames={{
            inputWrapper: 'bg-transparent bg-white shadow-none h-full',
            input: 'bg-transparent h-full',
          }}
          value={searchValue}
          placeholder="支持中英文输入，一键获取文献和总结..."
          onFocus={() => {
            setIsInputFocus(true);
          }}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          onKeyDown={(e) => {
            setIsInputFocus(true);
            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
              e.preventDefault();
              if (
                mainState.currentEntityInfo.isLoadingEntities ||
                !searchValue
              ) {
                return;
              }
              const params = new URLSearchParams();
              params.set('q', searchValue);
              params.set('mode', mainState.mode);
              router.push(`/result?${params.toString()}`);
            }
          }}
        />
        <Button
          className="bg-primary w-[40px] h-[40px] p-0 min-w-0 flex items-center justify-center rounded-full ml-[10px] shrink-0 data-[disabled=true]:opacity-40"
          isDisabled={!searchValue}
          onClick={() => {
            const params = new URLSearchParams();
            params.set('q', searchValue);
            params.set('mode', mainState.mode);
            router.push(`/result?${params.toString()}`);
          }}
        >
          <Image
            src={SearchIcon.src}
            width={20}
            height={20}
            alt="arrow-right"
          />
        </Button>
      </div>
      <div
        className={`${isInputFocus ? 'max-h-[600px]' : 'max-h-0'} transition-all overflow-hidden`}
      >
        <div className="border-solid border-1 border-divider mx-[20px]" />
        <div className="flex w-full px-[20px] py-2">
          <Button
            className={`rounded-full mr-4 ${mainState.mode === SearchMode.EN ? 'bg-[#006FEE33]' : ''}`}
            onClick={() => {
              mainState.changeMode(SearchMode.EN);
            }}
          >
            <Image
              src={PaperEnIcon}
              width={20}
              height={20}
              alt="English Papers"
            />
            英文文献
          </Button>
          <Button
            className={`rounded-full mr-4 ${mainState.mode === SearchMode.ZH_CN ? 'bg-[#006FEE33]' : ''}`}
            onClick={() => {
              mainState.changeMode(SearchMode.ZH_CN);
            }}
          >
            <Image
              src={PaperZhIcon}
              width={20}
              height={20}
              alt="Chinese Papers"
            />
            中文文献
          </Button>
          <Button
            className={`rounded-full mr-4 ${mainState.mode === SearchMode.FUND ? 'bg-[#006FEE33]' : ''}`}
            onClick={() => {
              mainState.changeMode(SearchMode.FUND);
            }}
          >
            <Image src={FundIcon} width={20} height={20} alt="Fund" />
            基金课题
          </Button>
        </div>
        <div className="flex w-full px-[20px]">
          <Listbox aria-label="Actions" items={listItems}>
            {(item) => (
              <ListboxItem
                key={item.key}
                endContent={item.endContent}
                onClick={() => {
                  const params = new URLSearchParams();
                  params.set('q', item.label);
                  params.set('mode', mainState.mode);
                  router.push(`/result?${params.toString()}`);
                }}
              >
                {item.label}
              </ListboxItem>
            )}
          </Listbox>
        </div>
      </div>
    </div>
  );
});

export default HomeTextArea;
